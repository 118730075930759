<template>
  <div class="new-center">
    <div class="top-banner">
      <img style="width: 100%;height: 50vh" src="@/assets/page4/banner.png" alt="">
      <div class="banner-title">砥砺前行十余载 踔厉奋发向未来</div>
      <div class="banner-menu">
        <div class="banner-menu-tab">
          <div :class="showMenu == 1 ? 'menu-active' : ''" @click="changeMenu(1)">项目展示</div>
          <div :class="showMenu == 2 ? 'menu-active' : ''" @click="changeMenu(2)">采购信息</div>
        </div>
      </div>
    </div>
    <div>
      <div class="project-main" ref="projectMain">
        <div v-for="(item,i) in projectList" :key="i" class="project-card">
          <div><img :src="BASE_URL + item.thumbnail" alt=""></div>
          <div>
            <div class="project-title" :title="item.businessTitle">{{ item.businessTitle }}</div>
            <div class="small-button" style="text-align: left;margin-left: 1vw;">
              <img style="width:0.36vw;" src="@/assets/page3/button.png" alt="">
              <span style="font-size: .7vw;margin-left: 5px;" @click="goToDetail(item.businessId)">查看项目</span> 
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;">
        <div v-show="showMore" class="common-button2" style="margin-top: 3.7vh;margin-bottom: 3.7vh;" @click="getMoreBusiness()">查看更多</div>
        <div v-show="!showMore" class="common-button2" style="margin-top: 3.7vh;margin-bottom: 3.7vh;background: grey;">暂无更多</div>
      </div>
    </div>
    
    <div class="buy-card" ref="buyMain">
      <div class="common-title" style="margin-bottom:4vh ;"><!--标题通用样式-->
        <div>采购信息</div>
        <img src="@/assets/page2/img1.png" alt="">
      </div>
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">
        <div class="buy-card-card" v-for="(item,i) in buyList" :key="i">
          <div class="buy-status" v-if="item.procureState==0"><img src="@/assets/page4/1.png" alt=""><span>开标</span></div>
          <div class="buy-status" v-if="item.procureState==1"><img src="@/assets/page4/2.png" alt=""><span>招标</span></div>
          <div class="buy-status" v-if="item.procureState==2"><img src="@/assets/page4/3.png" alt=""><span>中标</span></div>
          <div class="buy-card-title">{{ item.bidName }}</div>
          <div class="buy-card-info" v-if="item.procureState!=0">{{ item.shortdec }}</div>
          <div class="buy-card-info-0" v-if="item.procureState==0">开标时间：{{ item.bidTime }}</div>
          <div class="buy-card-info-0" v-if="item.procureState==0">开标业主：{{ item.bidMaster }}</div>
          <div class="buy-card-info-0" v-if="item.procureState==0">开标名称：{{ item.bidName }}</div>
          <div class="buy-card-info-0" v-if="item.procureState==0">开标地点：{{ item.bidPlace }}</div>
          <div class="small-button" style="text-align: left;margin-top: 1vh;">
            <img style="width:0.36vw;" src="@/assets/page3/button.png" alt="">
            <span style="font-size: .7vw;margin-left: 5px;" @click="goToDetail2(item.procureId)">阅读全文</span>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;">
        <div v-show="showMore2" class="common-button2" style="margin-top: 3.7vh;margin-bottom: 3.7vh;" @click="getMoreBuyList()">查看更多</div>
        <div v-show="!showMore2" class="common-button2" style="margin-top: 3.7vh;margin-bottom: 3.7vh;background: grey;">暂无更多</div>
      </div>
    </div>
    <Businessdetail @closeDialog="closeDialog" :detailID="detailID" :showDetail="showDetail"></Businessdetail>
    <Buydetail @closeDialog="closeDialog2" :detailID="detailID2" :showDetail="showDetail2"></Buydetail>
  </div>
</template>

<script>
import Businessdetail from "@/components/Businessdetail";
import Buydetail from "@/components/Buydetail";
import { getBusinessAll,getProCureAll } from "../../../js/api/index";
export default {
  components:{ Businessdetail,Buydetail },
  data() {
    return {
      BASE_URL:BASE_URL,
      projectList:[],
      buyList:[],
      showMenu: 1,
      showMore:true,
      showMore2:true,
      pageInfo:{
        pageNum:1,
        pageSize:9,
      },
      pageInfo2:{
        pageNum:1,
        pageSize:3,
      },
      detailID:1,
      showDetail:false,
      detailID2:1,
      showDetail2:false,
    };
  },
  computed: {},
  methods: {
    changeMenu(value) {
      this.showMenu = value;
      let targetElement;
      if(value == 1){
        targetElement = this.$refs.projectMain;
      }else if(value == 2){
        targetElement = this.$refs.buyMain;
      }
      targetElement.scrollIntoView({ behavior: 'smooth' });
    },
    //初始化项目信息
    initProjectListData(){
      getBusinessAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        this.projectList.push(...list);
        if(resData.pages == resData.pageNum){
          this.showMore = false;
        }
      });
    },
    //初始化采购信息
    initBuyListData(){
      getProCureAll(this.pageInfo2).then((res) => {
        let resData = res.data;
        let list = resData.list;
        this.buyList.push(...list);
        if(resData.pages == resData.pageNum){
          this.showMore2 = false;
        }
      });
    },
    getMoreBusiness(){
      this.pageInfo.pageNum+=1;
      getBusinessAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        this.projectList.push(...list);
        if(resData.pages == resData.pageNum){
          this.showMore = false;
        }
      });
    },
    getMoreBuyList(){
      this.pageInfo2.pageNum+=1;
      getProCureAll(this.pageInfo2).then((res) => {
        let resData = res.data;
        let list = resData.list;
        this.buyList.push(...list);
        if(resData.pages == resData.pageNum){
          this.showMore2 = false;
        }
      });
    },
    goToDetail(id){
      this.showDetail = true;
      this.detailID = id;
    },
    goToDetail2(id){
      // this.showDetail2 = true;
      // this.detailID2= id;
      this.$router.push({name:'buydetail',params: {id}}) // 只能用 name
    },
    closeDialog(val){
      this.showDetail = val;
    },
    closeDialog2(val){
      this.showDetail2 = val;
    }
  },
  mounted() {
   this.initProjectListData();
   this.initBuyListData();
  }
};
</script>

<style lang="scss" scoped>
.top-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .banner-title {
    position: absolute;
    font-size: 2vw;
    font-weight: 800;
    color: #FFFFFF;
  }
}
/**通用图片内切换按钮 */
.banner-menu {
  width: 100vw;
  position: absolute;
  bottom: 0;
  height: 6vh;
  background: rgba(2555, 255, 255, 0.4);

  .banner-menu-tab {
    display: flex;
    height: 100%;
    padding-left: 18.75vw;
    padding-right: 18.75vw;

    >div {
      width: 50%;
      color: #FFFFFF;
      font-size: 1vw;
      transition: all ease .3s;
      cursor: pointer;
      line-height: 6vh;
    }

    >div.menu-active,
    >div:hover {
      background: #1F4692;
    }
  }

}
//通用按钮样式
.common-button{
  width: 5.21vw;
  height: 2.96vh;
  line-height: 2.96vh;
  background: #1F4692;
  font-size: .7vw;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  margin-top: 3.7vh;
}
.common-button2{
  width: 6.25vw;
  height: 3.33vh;
  line-height: 3.33vh;
  background: #1F4692;
  font-size: .7vw;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
}
/**通用图片内切换按钮 */
.banner-menu {
  width: 100vw;
  position: absolute;
  bottom: 0;
  height: 6vh;
  background: rgba(2555, 255, 255, 0.4);

  .banner-menu-tab {
    display: flex;
    height: 100%;
    padding-left: 18.75vw;
    padding-right: 18.75vw;

    >div {
      width: 50%;
      color: #FFFFFF;
      font-size: 1vw;
      transition: all ease .3s;
      cursor: pointer;
      line-height: 6vh;
    }

    >div.menu-active,
    >div:hover {
      background: #1F4692;
    }
  }

}
.common-title {/**通用标题 */
  width: 5vw;
  text-align: left;
  div {
    color: #393939;
    font-size: 1.1vw;
  }

  img {
    width: 50%;
  }
}
.small-button{//通用小按钮
  cursor: pointer;
}
.small-button:hover{
  color: #1F4692;
}

.project-main{
  padding-left: 18.75vw;
  padding-right: 18.75vw;
  padding-top: 5.28vh;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .project-card{
    width: 32%;
    height: 32vh;
    background: #F9F9F9;
    margin-bottom: 1.5vh;
    >div{
      width: 100%;
      height: 25vh;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .project-title{
      margin:1vh 1vw 0.5vh;
      font-size: .8vw;
      font-weight: bold;
      color: #333333;
      white-space: nowrap; /* 禁止换行 */
      overflow: hidden; /* 隐藏溢出内容 */
      text-overflow: ellipsis; /* 使用省略号*/
    }
  }
  .project-card:nth-child(3n-1){
    margin: 0 1vw;
  }
}
.buy-card{
  padding-left: 18.75vw;
  padding-right: 18.75vw;
  padding-top: 5.28vh;
  background: #F3F4F4;
  text-align: left;
  .buy-card-card{
    background: #FFFFFF;
    width: 27%;
    height: 22vh;
    transition: all ease .2s;
    padding: 4vh 1.5vw 2vh;
    position: relative;
    .buy-status{
      position: absolute;
      right: 1vw;
      top:1vh;
      width: 3.5vw;
      height: 2.5vh;
      img{
        width: 100%;
        height: 100%;
      }
      >span{
        font-weight: bold;
        color: #FFFFFF;
        font-size: .6vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .buy-card-title{
      font-size: 1vw;
      font-weight: bold;
      color: #131313;
      white-space: nowrap; 
      overflow: hidden; 
      text-overflow: ellipsis;
      padding-bottom: 1vh;
      border-bottom: 1px solid #D3D3D3; 
    }
    .buy-card-info{
      font-size: .7vw;
      color: #131313;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      line-height: 1.5;
      max-height: 8em;
      margin-top: 2vh;
    }
    .buy-card-info-0{
      font-size: .7vw;
      color: #131313;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      line-height: 1;
      max-height: 8em;
      margin-top: 2vh;
    }
  }
  .buy-card-card:hover{
    box-shadow: 0px 1px 15px 1px rgba(24,24,24,0.06);
  }
  .buy-card-card:nth-child(even){
    margin: 0 1vw 1.85vh 1vw;
  }

}
</style>